@import "../../../mixins.scss";

.klesia {
  .main .header {
    position: fixed;
    top: 0;
    //right: 0;
    display: flex;
    left: 86px;
    width: calc(100vw - 86px);
    //width: calc(100vw - 86px);
    justify-content: flex-end;
    background: white;
    // padding: 0.7rem 1.2rem;
    padding: 0.4rem 1rem;
    z-index: 1000000;
    transform: translate3d(0,0,0);
    transition: transform 0.3s ease;
    border-bottom: solid 1px rgba(#00d0c5, 0.2);

    @media (max-width: 768px){
      &.articleView {
        .scroll-block {
          display: flex;
        }

        .center-bloc {
          padding-top: 4px;
        }
      }
    }

    @media (max-width: 1025px) and (min-height: 900px){
      &.articleView {
        .scroll-block {
          display: flex;
        }

        .center-bloc {
          padding-top: 4px;
        }
      }
    }

    &.menuNumeroShow, &.isSearching, &.menuShow {

      .numeroBloc {
        @media (max-width: 768px) {
          .title, .pipe {
            display: none;
          }
        }
        @media (min-height: 900px) and (max-width: 968px){
          .title, .pipe {
            display: none;
          }
        }
      }
    }

    &.menuNumeroShow, &.isSearching, &.menuShow, &.isPage {
      .scroll-block {
        visibility: hidden;
      }
      .center-bloc {
        padding-top: 0;
      }
    }

    &.isPage:not(.isSearching):not(.menuNumeroShow):not(.menuShow) {
      @media (max-width: 425px) {
        .menu-search {
          display: block !important;
        }
        .center-bloc .title {
          display: none;
        }
      }
    }

    &.couvertureView:not(.menuShow):not(.menuNumeroShow):not(.isSearching):not(.isPage) {
      border-bottom: solid 1px transparent;
      background: transparent;
      .center-bloc {
        visibility: hidden;
      }
      .left-bloc {
        visibility: hidden;
      }
      .accessibilite {
        button, span {
          color: black;
        }
      }
    }

    &.menuShow {
    }

    @media( max-width: 425px) {
      //padding-right: 4rem;
      padding-right: 1.2rem;
      &.isPage {
        padding-right: 1.2rem;
      }
    }

  &:not(.menuShow):not(.menuNumeroShow):not(.isSearching).scrollDown {
      transform: translate3d(0, -100%, 0);
      transition: all 0.4s ease;
    }

    .imprimer-btn {
      position: relative;
      display: none;
      margin-right: 1rem;
    }

    &.isPage {
      border-bottom: solid 1px #E9E9E9;
      &:not(.menuShow) {
        .inner .right-bloc .searchBtn.isSearching {
          //border: solid 1px black !important;
          &:hover {
            //border: solid 1px black !important;
          }
        }
      }
    }

    .fx-backgrd {
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
      z-index: -1;

      &:after {
        //@include wait(#ececec)
        @include wait(#828282);
      }

    }

    .scrollbar-container {
      height: 200px;
    }

    .accessibilite {
      display: flex;
      align-items: center;
      margin-right: 0;
      transform: translateY(2px);

      @media( max-width: 768px){
        margin-right: 0.7rem;
      }

      @media( max-width: 425px){
        margin-right: 0;
        transform: translateY(0) translateX(5px);
      }

      button {
        text-transform: uppercase;
        background: transparent;
        border:0 !important;
        font-size: 0.70rem;
        color: #000000;
        font-weight: 600;
        cursor: pointer;

        @media (max-width: 425px) {
          font-size: 0.9rem;
          padding-left: 7px;
          padding-right: 7px;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      span {
        color: black;
      }

      @media( max-width: 425px) {
        //display: none;
      }
    }

    .menu-numero {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      //margin-top: 0.3rem;
      overflow: visible !important;
      //border: 1px solid #ffffff;
      border-radius: 3px;

      button {
        border: 1px solid transparent !important;
        border-radius: 5px;
      }

      &:after {
        @include wait(#ececec);
      }

      .numeros-menu-outer {
        width: calc(100% + 0px);
        left: 0;
        // border-top: solid 1px transparent;
        box-shadow: 0 4px 15px 0 rgba(15,15,89,0.10);

        .ps__thumb-y {
          background-color: #ff4a7a;
          margin-right: 5px;
        }

        /*.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
          margin-right: 5px;
        }*/

      }

      .show-more-btn {
        text-align: center;
        padding-top: 0.5rem;
        padding-bottom: 0.4rem;
        font-size: 0.6rem;
        font-weight: 600;
      }

      .numeros-menu {
        height: auto;
        overflow-y: auto;
        margin-bottom: 0px;

        &.show-more {
          height: 150px;
          overflow-y: scroll;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #fff;
        }

        &::-webkit-scrollbar-thumb {
          background: $red;
        }

        &::-webkit-scrollbar-thumb:hover {
          background: #4E49FC;
        }

      }

      &.menuShow {
        color: black;

        .menu-numeros-btn {
          background: white;
          color: black;
          border: 1px solid white !important;
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }

        .menu-numeros-btn {
          color: black;
          text-decoration: none;

          i {
            color: #0F0F59;
          }

        }

        .numeros-menu-outer {
          //border-top: solid 1px rgba(151, 151, 151, 0.12156862745098039);
        }

      }

    }

    .scroll-block {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 6px;
      -webkit-transform: translateY(100%);
      transform: translateY(0%);
      border-top: solid 0px #dbdbdb;

      @media (max-width: 768px){
        .articleView {
          display: flex;
        }
      }

      @media (max-width: 1025px) and (min-height: 900px){
        .articleView & {
          display: flex;
        }
      }

    //> div:first-child {
      //  flex-basis: 272px;
      //}

      .scroll-inner {
        min-width: 400px;
        width: 100%;
        margin: 0 auto;
        max-width: 900px;
        background: #dbdbdb;
        display: none;
        height: 100%;

        @media (max-width: 768px){
          display: flex;
        }

        @media (max-width: 1025px) and (min-height: 900px){
          display: flex;
        }

        .scroll-fx {
          height: 100%;
          width: 100% !important;
          transform: scaleX(0);
          transform-origin: left;
          background: #00d0c5;
          transition: transform 0.1s ease;
        }

      }

    }

    .inner {
      display: flex;
      justify-items: center;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      background: transparent;

      .right-bloc {
        display: flex;
        z-index: 1;
        > div {
          > a:first-child {
            > i:before {
              color: black;
            }

            margin-right: 0.8rem;
          }

          display: flex;
          align-items: center;
        }

        .menu-search {
          @media(max-width: 425px){
            display: none;
            &.isPage {
              display: block;
            }
          }
        }

        .searchBtn {
          position: relative;
          background: #000000;
          border-radius: 100%;
          font-size: 0.9rem;
          width: 1.6rem;
          justify-content: center;
          align-items: center;
          display: flex;
          height: 1.6rem;
          margin-left: 1.2rem;
          border: solid 1px white;
          transform: scale(1);
          padding:0;
          cursor: pointer;

          @at-root body:not(.touch) #{&} {
            &:hover {
              i {
                transform: scale(1.1);
                transition: transform 0.2s ease;
              }
            }
          }

          @media( max-width: 768px){
            margin-left: 0.5rem;
          }

          @media( max-width: 425px) {
            font-size: 1.6rem;
            width: 2.2rem;
            height: 2.2rem;
            margin-left: 1rem;
            padding: 0;

            i {
              transform: translate(0, 1px) !important;
            }

          }

          &:after {
            @include wait(#ececec);
          }

          i {
            display: flex;
            //transform: translate(0, 2px);
            transition: transform 0.1s ease;
            &:before {
              color: white;
            }
          }


          &.isSearching {
            border: solid 1px #00d0c5 !important;
            background: #00d0c5;
            //transform: scale(1.2);
            transition: transform 0.6s ease;

            @at-root body:not(.touch) #{&} {
              &:hover {
                border: solid 1px #00d0c5 !important;
                background: #00d0c5;
                i {
                  &:before {
                    color: white;
                  }
                }
              }
            }

            i {
              font-size: 0.65rem;
              //transform: translate(0, 1px);
              &:before {
                color: white;
              }
            }
          }

        }

      }
      .center-bloc {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        text-align: center;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.8rem;
        .sub {
          font-weight: 600;
          color: rgba(#000000, 0.55);
        }
        .title, .pipe, .sub {
          font-size: 0.8rem;

          @media (max-width: 425px) {
            font-size: 1.1rem;
          }

        }
        @media (max-width: 425px) {
          font-size: 1.3rem;
        }
      }

      .left-bloc {
        font-weight: 800;
        font-size: 0.7rem;
        cursor: pointer;
        color: #000000;
        order: 0;
        z-index: 1;

        span {
          display: flex;
          align-items: center;
          &.title-nav {
            @media (max-width: 425px) {
              display: none;
            }
          }
        }

        @at-root body:not(.touch) #{&} {
          &:hover {
            i {
              transform: translateX(3px);
              transition: transform 0.3s ease;
            }
          }
        }

        i {
          transform: translateX(0);
          color: #f01b8b;
          margin-right: 0.8rem;
          font-size: 1rem;
          transition: transform 0.2s ease;
          @media (max-width: 425px) {
            font-size: 1.4rem;
          }
        }
      }

      .center-bloc {
        color: #000000;
        font-weight: 400;

        @media (max-width: 425px) {
          font-weight: 400;
        }

        .title {
          font-weight: 800;
        }

        a {
          color: #000000;
          @at-root body:not(.touch) #{&} {
            &:hover {
              color: #000000;
            }
          }
        }
      }

      .logo {
        img {
          width: 130px;
        }

        &:after {
          @include wait(#dbdbdb);
        }

      }

      h1 {
        padding-left: 1.7rem;
        //font-size: 1.5rem;
        margin-bottom: 0;
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;

        a {
          color: white;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          > div {
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 2.3rem;
            text-transform: uppercase;
          }

          img {
            height: 3.1rem;
            margin-right: 0.4rem;
            width: auto;
          }

          &:after {
            // @include wait(#ececec);
          }

        }
      }

    }

    .menu-toggle-btn {
      background: #4e49fc;
      color: white;
      border: 0;
      outline: none;
      width: 100px;
      height: 90px;
      margin-left: 3em;
      cursor: pointer;
    }

  }

  .menu-numeros-btn {
    font-size: 0.9rem;
    font-weight: 600;
    //padding: 0.9rem;
    //padding-top: 0.6rem;
    //padding-bottom: 0.6rem;
    //padding-right: 0.9rem;
    font-weight: 400;
    text-transform: uppercase;
    padding: 1.3rem;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    line-height: 0px;
    padding-right: 1.3rem;
    background: transparent;
    cursor: pointer;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;

    @media(max-width: 768px){
      // max-width: 291px;
      min-width: 260px;
    }

    .inner {
      display: flex;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        transform: translateY(0.4rem);
        background: white;
        height: 1px;
      }

    }

    span {
      margin-right: 3rem;
      font-size: 0.76rem;
      position: relative;
      display: block;
      @media(max-width: 768px){
        margin-right: 0;
      }
    }

    i {
      color: #fff;
      font-size: 1rem;
      &.ico-klesia-chevron-up {
        transform: translateY(-2px);
      }
    }

  }

  .numeros-menu-outer {
    position: absolute;
    //background: #f1f1f1;
    background: white;
    padding: 0px;
    padding-bottom: 0.5rem !important;
    z-index: 1;
    // bottom: 0rem;
    bottom: 1px;
    left: 0;
    transform: translateY(100%);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    .inner-numero-menu {
      /*max-height: 150px;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;*/
    }

  }

  .numeros-menu {

    .inner-numero-menu {
      max-height: 150px;
    }

    li {
      display: flex;
      margin-bottom: 0;
      align-items: center;
      cursor: pointer;

      &:last-child {
        border-bottom: 0;
      }

      &:hover {
        background: rgba(163, 163, 163, 0.05);
      }

      &.current {
        background: rgba(163, 163, 163, 0.1);
        border-bottom: solid 1px transparent;

        a {
          font-weight: 600;
          display: flex;
          justify-content: space-between;
          align-items: center;

          i {
            color: $red;
            padding-right: 0.2rem;
            font-size: 1rem;
          }

        }

      }

    }

    a {
      color: black;
      text-transform: uppercase;
      font-size: 0.75rem;
      line-height: 1.2rem;
      padding: 7%;
      padding-left: 10%;
      width: 100%;
    }

  }
}

@import "./header-media.scss";
