@import "../../mixins.scss";

.klesia {
  letter-spacing: 0.3px !important;

  .couverture {
    height: 100vh;
    width: 100%;
    background-color: #fff;
    //background-image: linear-gradient(47deg, #000000 0%, #00d0c5 100%);

    .couvertureInner {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 2rem;
      padding-bottom: 2rem;
      height: 100%;
      width: 100%;
      position: relative;
      overflow: hidden;

      @media(max-width: 1024px){
        padding-left: 2rem;
        padding-right: 2rem;
      }

      @media(max-width: 1024px){
        flex-direction: column;
        justify-content: flex-start;
        padding-top: 10vh;
        padding-left: 2rem;
        padding-right: 2rem;
        //height: 90vh;
      }

      .couvertureInfos {
        //transform: translateX(-1.4rem);
        padding-left: 2.5rem;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        align-items: flex-start;
        //max-width: 350px;
        max-width: 400px;
        position: relative;
        z-index: 1;

        @media (max-width: 1024px) {
          align-items: center;
          text-align: center;
        }

        @media(max-width: 1024px){
          position: absolute;
          bottom: 3rem;
          left: 0;
          padding-left: 2rem;
          transform: translateX(0);
          padding-right: 2rem;
          width: 100%;
          display: flex;
          justify-content: center;
          max-width: initial;
          //align-items: center;
        }

        @media(max-width: 425px){
          align-items: stretch;
          text-align: left;
        }

        a.start-btn {
          display: flex;
          padding: 0.8rem 1.72rem 0.86rem 1.72rem;
          margin-top: 1rem;
          color: white;
          background-color: #f0188b;
          text-transform: uppercase;
          position: relative;
          font-size: 20px;
          line-height: 24px;

          @media(max-width: 425px) {
            margin: 0 auto;
            padding: 1.1rem 1.5rem;
            margin-top: 2rem;
            width: 73vw;
            div {
              width: 100%;
              text-align: center;
            }
          }

          span {
            color: white;
            font-weight: 400;
            @media(max-width: 425px) {
              padding-left: 0;
            }
          }

          &:hover {
            &:after {
              transform: translate(0px, 5px) scaleX(0);
              transform-origin: left;
              transition: all 0.4s ease;
            }
          }

          &:after {
            content: "";
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            transform-origin: left;
            transform: translate(0, 5px) scaleX(1);
            z-index: -1;
            transition: all 0.5s ease;
          }

        }

        .subtitle {
          font-size: 22px;
          color: #000000;
          line-height: 29px;
          margin-bottom: 18px;

          @media(max-width: 1024px) {
            br {
              display: none;
            }
            margin-bottom: 2rem;
          }
        }

        h1 {
          margin-bottom: 0.7rem;
          font-size: 40px;
          color: #000000;
          line-height: 46px;

          @media(max-width: 1180px) {
            font-size: 2.5rem;
            line-height: 3rem;
          }

          @media(max-width: 1024px) {
            font-size: 2rem;
            line-height: 2.4rem;
            padding-left: 1rem;

            br {
              display: none;
            }
          }
        }
        span {
          color: black;
          font-size: 1rem;
          line-height: 1.3rem;
          @media(max-width: 1024px) {
            padding-left: 1rem;
            font-size: 1.1rem;
          }
        }
      }

      .couvertureImage {
        max-width: 641px;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        max-height: 489px;
        width: 100%;
        border-radius: 5px;
        background-color: #23447A;
        position: relative;

        @media (min-height: 900px) and (max-width: 1025px) {
          max-width: 72%;
        }

        @media(max-width: 425px) {
          max-height: 52vh !important;
          max-width: 73vw;
        }

        @media(max-width: 1024px){
          max-height: 60vh;
          //max-width: 80vw;
        }

        .imageOuter {
          width: 100%;
          height: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            will-change: opacity;
            opacity: 0;

            &.loaded {
              opacity: 1;
              transition: opacity 0.3s ease 0.300s;
            }
          }
        }
      }
    }

  }
}

@import "./couverture-media.scss";
