//@media (max-width: 768px){

@media (min-height: 900px) and (min-width: 769px) and (max-width: 1025px){
  .klesia {
    .menu {
      .credits {
        //width: 305px !important;
      }

    }
  }
}
@media (min-height: 900px) and (max-width: 1025px){

  .klesia {

    .footer-menu ul li {
      //margin-right: 1rem !important;
      margin-right: 0.7rem !important;
      font-size: 0.7rem;
    }

    .footer-menu .credits {
      width: 213px;
      font-size: 0.7rem !important;
      span{
        margin-right: 0 !important;
        &:nth-child(2){
          margin-left: 6px;
        }
      }
    }

    .menu, .footer-menu {
      left: 72px;
      width: 100%;
      //width: calc(100vw - 72px);
    }
    .footer-menu {
      padding: 0.7rem 1rem;
    }

    .menu-inner {

      .articles-test {

        & > ul {
          & > li {
            //flex: 0 1 calc(52% - 58px / 2);
            flex-basis: calc(100% / 2);
            padding: 15px;
          }
        }

      }

    }
  }

}

@media (max-width: 425px){

  .klesia {

    .footer-menu {
      position: relative;
      padding-left: 0;
      padding-left: 2rem;
      padding-top: 2rem;
      padding-bottom: 4rem;
      height: auto !important;
      border-top: solid 1px rgba(0, 0, 0, 0.05);
      width: 100vw;
      left: 0;

      .inner-footer-menu {
        max-width: 100%;
        transform: translateX(0);
        flex-direction: column;

        .credits {
          margin-top: 1.5rem;
          text-align: center;
          font-size: 1rem;
          line-height: 1.8rem;
        }

      }

      ul {
        flex-direction: column;
        text-align: center;

        li {
          margin-right: 0 !important;
          font-size: 1.2rem;
          margin-bottom: 0.7rem;
        }

      }

    }

    .menu {
      width: calc(100vw);
      //left: 0;
      //overflow: scroll;
      //height: 100vh !important;
      //background:white;
      background-color: #257fbc;
      //-webkit-overflow-scrolling: touch;
      padding-bottom: 4rem;


      .menu-inner {
        //padding-left: 6px;
        //padding-right: 6px;
        flex-direction: column;
        position: relative;
        height: auto;


        .categories .timeline-circle-category .reading-outer {
          transform: translateX(-1.82rem);
          z-index: 0;
        }

        .timeline-circle-category text {
          line-height: 1.3rem;
          font-size: 1.7rem;
          //transform: translate(-0.06rem,0);
          transform: translate(-0.06rem, 0.43rem);
        }

        .categories {
          .chapitre-title {
            padding: 1.7rem;
            padding-bottom: 0.2rem;
            background: white;
            font-weight: bold;
            font-size: 1.87rem;
            line-height: 1.9rem;
            padding-top: 2.7rem;
            padding-left: 1.5rem;
          }
        }

        .menu-numero {
          padding-left: 9px;
          padding-right: 6px;
          position: relative;
          display: none;

          i.icon-chevron_down {
            color: black;
            position: absolute;
            top: 50%;
            transform: translateY(calc(-50% - 1px));
            right: 1.5rem;
            font-size: 1.7rem;
            z-index: -1;
          }

          .numeros-menu-outer {
            position: relative;
            background: transparent;
            padding: 12px;
            z-index: 1;
            width: 99%;
            bottom: 0;
            left: 0;
            /* -webkit-transform: translateY(100%); */
            -webkit-transform: translateY(0%);
            transform: translateY(0%);
            padding-top: 0;
            padding-bottom: 0 !important;
            border: solid 1px #e6e6e6;
            margin-top: 0;
            margin-bottom: 0.6rem;

            select {
              color: black;
              padding-top: 1.5rem;
              padding-bottom: 1.5rem;
              width: 100%;
              background: transparent;
              font-size: 1.4rem;
              padding-left: 0.7rem;
              border: 0 !important;
              outline: none;
            }

            .numeros-menu li {
              margin-bottom: 2.2em;
              margin-top: 1rem;

              a {
                font-size: 1.05rem;
                color: white;
              }

            }

          }

          .menu-numeros-btn {
            font-size: 0.9rem;
          }

        }

        .articles-test {
          // margin-top: 1.4rem;
          margin-top: 0;
          overflow: inherit;
          padding-top: 0;

          > ul {
            padding-bottom: 3rem;

            li {
              padding-bottom: 1.1rem;
              padding-top: 1.1rem;
            }

          }

        }

        .articles-test::-webkit-scrollbar {
          width: 5px;
        }

        .articles {
          height: auto;
          padding-bottom: 1rem;
          padding-top: 0;
          overflow: hidden;
          padding-left: 1.5rem;
          padding-right: 1.5rem;

          a {
            font-size: 1.2rem;

            i {
              transform: translate(0px, 4px);
            }

            span {
              line-height: 1.9rem;
            }

          }

        }

        .categories {
          .reading {
            padding: 0.4rem;
            padding-right: 0.7rem;
            padding-left: 0.9rem;
            border-radius: 1rem;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            font-size: 0.6rem;
            transform: translate(-4.4rem, 0px);
          }
        }

        .articles-test > ul {
          & > li {
            flex: 0 1 100%;
            &:nth-child(1) {
              padding-top: 0;
            }
          }
        }

      }

    }
  }


}
