@import "../../mixins.scss";

.klesia {

  .footer-menu {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 86px;
    width: calc(100vw - 86px);
    background: white;
    border-top: solid 1px rgba(0, 0, 0, 0.25);
    //opacity: 0;
    padding: 0.7rem 1.7rem;
    padding-top: 0.8rem;
    justify-content: center;
    z-index: 1;

    .inner-footer-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .credits {
        font-size: 0.7rem;
        color: #000000;
        text-transform: initial;
        .propulse {
          margin-right: 0;
          @media (max-width: 1280px) {
            display: none;
          }
        }
        span {
          margin-right: 1.6rem;
          &:last-child {
            margin-right: 0;
          }
        }
        a {
          color: #000000;
          font-weight: 600;
          &:hover {
            color:#000000;
            text-decoration: underline !important;
          }
        }
      }

    }

    ul {
      display: flex;
      justify-content: flex-start;

      li {
        //color: white;
        color: #000000;
        // margin-right: 3rem !important;
        margin-right: 2.4rem !important;
        cursor: pointer;
        font-size: 0.7rem;
        font-weight: 400;

        @media (max-width: 1025px){
          margin-right: 1.5rem !important;
        }

        @at-root body:not(.touch) #{&} {
          *:hover, &:hover {
            text-decoration: underline;
          }
        }

        a {
          color: #000000;

          @at-root body:not(.touch) #{&} {
            &:hover {
              text-decoration: underline !important;
            }
          }
        }
      }

    }

  }

  .chapitre {
    .timeline-circle-category {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(20%, -20%);
    }
  }

  .closeListingMenu {
    width: 50%;
    //background: transparent;
    background: rgba(255, 255, 255, 0.78);
    height: 100%;

    @media(max-width: 1024px){
      width: 20%;
    }

    @media(max-width: 425px){
      display: none;
    }
  }

  .articlesListingMenu {
    padding: 2rem;
    width: 50%;
    background: white;
    box-shadow: 0 0 60px 0 rgba(25, 34, 59, 0.13);
    overflow: auto;
    height: 100%;

    @media(max-width: 1024px){
      width: 100%;
    }

    @media(max-width: 425px){
      width: 100%;
      padding-bottom: 12rem;
      box-shadow: initial;
    }

  .categoryHead {
      display: flex;
      flex-direction: row;
      align-items: center;
      // margin-top: 1rem;
      //margin-bottom: 1rem;
      margin-bottom: 1.5rem;
      margin-top: 2.6rem;

      .chapitre-title {
        font-size: 1.6rem;
        color: #000000;
        font-weight: 400;
        margin-left: 0.7rem;

        @media (max-width: 425px) {
          font-size: 1.7rem;
          margin-bottom: 0.4rem;
        }
      }
    }
    .head {
      display: flex;
      justify-content: space-between;
      .back-btn {
        color: #000000;
        font-size: 0.8rem;
        font-weight: 800;
        display: flex;
        align-items: center;
        cursor: pointer;

        @at-root body:not(.touch) #{&} {
          &:hover {
            i {
              transform: translateX(3px);
              transition: transform 0.3s ease;
            }
          }
        }

        i {
          transform: translateX(0);
          color: #00d0c5;
          margin-right: 0.8rem;
          font-size: 1rem;
          transition: transform 0.2s ease;
        }

        @media(max-width: 425px){
          visibility: hidden;
        }
      }
      .articlesLus {
        font-size: 0.8rem;
        font-weight: 400;
        color: #00d0c5;

        @media (max-width: 425px) {
          font-size: 1rem;
        }

        span{
          font-weight: 800;
        }
      }
    }
    li {
      list-style-type: none;
      border: solid 1px;
      border-radius: 3px;
      margin-bottom: 0.8rem;
      &.unread {
        opacity: 0.4
      }
      @at-root body:not(.touch) #{&} {
        &:hover {
          opacity: 1;
          transition: opacity 0.2s ease;
        }
      }
      a {
        padding: 0.68rem 1rem 0.52rem 1rem;
        display: block;
        font-size: 0.8rem;
        font-weight: 300;
        line-height: 1.6rem;
        color: #000000;
      }
    }

    .articles-after-list {
      border-top: solid 3px #efefef;
      margin-top: 1.5rem;
      padding-top: 1.5rem;
    }

    .articles-before-list {
      border-bottom: solid 3px #efefef;
      margin-bottom: 1.5rem;
      padding-bottom: 1.5rem;
    }

    .articles-after-list, .articles-before-list {
      li {
        list-style-type: none;
        border: solid 1px #00d0c5;
        a {
          padding: 0.8rem 1rem;
          display: block;
          color: #000000;
        }
      }
    }
    .subcategories {

      .subcategory {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: 425px) {
          font-size: 1.3rem;
        }

        .subcategory-title {
          padding: 0.8rem 1rem;
          color: #00d0c5;
          font-size: 0.8rem;
          background: rgba(#00d0c5, 0.1);
          text-transform: uppercase;
          font-weight: 800;
          justify-content: space-between;
          display: flex;

          @media (max-width: 425px) {
            font-size: 1rem;
            margin-bottom: 0.9rem;
          }

          &.unread {
            opacity: 0.3;
          }
          .reads {
            font-weight: 400;
            .number {
              font-weight: 800;
            }
          }
        }
        .subcategory-title, li {
          a {
            padding: 0.8rem 1rem;
            display: block;
            color: #000000;
          }
          width: 100%;
          margin-bottom: 0.8rem;
          border: solid 2px #00d0c5;
          border-radius: 3px;
        }
        li{
          border-width: 1px;
          list-style-type: none;
        }
      }
    }
  }

  .timeline-circle-category {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    z-index: 0;
    //overflow: hidden;
    padding: 4px;
    border-radius: 100%;
    background-color: white;
    //width: 51px;

    @media (max-width: 425px) {
      display: none;
    }

    .reading-outer {
      background: #00d0c5;
      border-radius: 30px;
      padding: 5px;
      padding-top: 6px;
      padding-right: 1.1rem;
      padding-left: 0.8rem;
      color: white;
      top: 50%;
      right: 16px;
      font-weight: 600;
      font-size: 0.62rem;
      position: absolute;
      width: 6rem;
      flex-shrink: 0;
      display: flex;
      transform: translateY(-50%);
      z-index: -1;
    }

    .reading {
      /*background: $red;
      color: white;
      padding: 0.13rem;
      padding-right: 0.7rem;
      padding-left: 1rem;
      border-radius: 1rem;
      font-size: 0.57rem;
      font-weight: 800;
      //transform: translate(-4.4rem, 0px);
      z-index: 1;
      text-transform: uppercase;
      animation: toLeft 0.6s ease 2.5s forwards;*/
    }

    svg {
      //z-index: -1;
      g {
        transform: translate(1px, 1px);
      }
    }
    text {
      color: white;
      line-height: 1.3rem;
      font-size: 1rem;
      font-weight: 800;
      transform: translate(-0.06rem, 0.32rem);
    }
    circle:first-child {
      fill: transparent;
      stroke: $red;
      stroke-width: 2;
      stroke-dasharray: 250;
      stroke-dashoffset: 250;
      transform: scale(-1, -1) translate(-42px, -42px);
      // transition: stroke-dashoffset 3s ease;
    }
    circle:nth-child(2){
      transform: translate(5px, 5px);
      // stroke: $red;
      fill: $red;
    }
  }

  @keyframes revealMenu {
    0% {
      opacity: 0;
      transform: translate3d(20px, 0px, 0px);
    }
    100% {
      opacity: 1;
      transform: translate3d(0px, 0px, 0px);
    }
  }

  &.absoluteMenuInner {
    position: fixed;
    display: flex;
    flex-direction: row;
    top: 0;
    right: 0;
    width: calc(100vw - 86px);
    height: 100vh;
    z-index: 100000000;
    background: transparent;
    animation: revealMenu 0.3s ease-in-out;

    @media(max-width: 425px){
      width: 100vw;
      height: 100vh !important;
    }
  }

  .menu {
    //z-index: 10;
    z-index: 130000;
    //z-index: 1300000000;
    //position: absolute;
    //top: 0;
    //left: 0;
    //height: 100vh;
    //width: 100%;
    border-left: 0 !important;
    //background-color: #4E49FC;
    // background-color: transparent;
    background-color: white;
    //overflow: auto;
    //width: calc(100% - 120px);
    //margin-left: 120px;
    //position: fixed;
    display: none;
    //-webkit-overflow-scrolling: touch;
    //left: 86px;
    //height: 100vh;
    padding-top: 1rem;
    width: 100%;

    @media (max-width: 425px) {
      padding-top: 2rem;
    }

    &.toggled {
      display: block;
    }

  }

  .menu-inner {
    width: 100%;
    background-color: transparent;
    display: flex;
    padding-top: 0;
    //padding-left: 48px;
    padding-left: 1rem;
    padding-right: 1rem;
    will-change: opacity;

    .chapitre-title {
      padding: 1.7rem;
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
      background: white;
      font-size: 1em;
      font-weight: 8s00;
      text-transform: uppercase;
      line-height: 1.4rem;
      margin-top: 0 !important;
      color: #000000;
      padding-top: 1.3rem;
      display: flex;
      padding-top: 0.1rem;

      @media(max-width: 425px){
        font-size: 1.8em;
        line-height: 2rem;
        margin-top: 0.8rem !important;
      }

      @media(max-width: 1024px) {
        margin-top: 0.6rem !important;
      }

      &.no-read-yet {
        color: black;
      }
      &.readed {
        color: lighten(black, 70%);
      }
    }

    .categories {
      font-size: 1.4rem;
      color: #000;
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      background-color: #ededed;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;

      .infosCategory {
        background: white;
        padding: 0.9rem;
        color: #000000;

        @media (max-width: 425px) {
          padding: 1.3rem;
        }

        &.en_cours {
          .head {
            color: #23447A;
          }
        }
        &.lu {
          .head {
            color: #00d0c5;
          }
        }

        .title {
          font-size: 1.07rem;
          font-weight: 400;
          display: block;
          line-height: 1.5rem;

          @media (max-width: 425px) {
            font-size: 1.5rem;
            line-height: 1.6rem;
          }
        }

        .head {
          display: flex;
          justify-content: flex-start;
          font-weight: 800;
          font-size: 0.70rem;
          margin-bottom: 0.4rem;
          color: #00d0c5;
          line-height: 22px;

          @media (max-width: 425px) {
            font-size: 1rem;
            margin-bottom: 0.9rem;
          }

          .articlesLus {
            margin-left: 1rem;
            font-weight: 400;

            span {
              font-weight: 800;
            }
          }
        }
      }

      .chapitre-wallpaper {
        display: block;
        height: 160px;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      .number {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: 9px;
        color: #fff;
        font-weight: bold;
        font-size: 1.4rem;
        margin-right: 26px;
        transform: translate(22%, -23%);
        z-index: 0;

        .inner {
          background-color: #FA5057;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
          border-top-right-radius: 3px;
        }

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 2px;
          width: 20px;
          height: 11px;
          background: #c53c41;
          clip-path: polygon(37% 0%, 100% 0%, 100% 100%, 0% 100%);
          z-index: -1;
        }

      }

    }

    .articles-outer {
      padding-left: 10px;
      padding-right: 10px;
      padding-bottom: 20px;
      padding-top: 10px;
    }

    .articles {
      padding: 1.1rem;
      //height: 249px;
      height: 241px;
      margin: 0 auto;
      margin-bottom: 0;
      width: calc(100%);
      //margin-top: 12px;
      padding-right: 21px !important;
      padding-top: 0;
      overflow-y: auto;
      padding-bottom: 10px;
      direction: ltr;
      padding-left: 1rem;
      background: white;
      margin-top: 18px;

      .articles-after-list {
        margin-top: 0.8rem;
        border-top: solid 6px #dfdeec4f;
        padding-top: 0.3rem;
      }

      span.subcategory-title {
        // background: rgba(151, 151, 151, 0.24);
        border-radius: 2px;
        padding: 0rem;
        padding-top: 0rem;
        padding-bottom: 0.38rem;
        display: block;
        // color: rgba(0, 0, 0, 0.55);
        color: #000000;
        text-transform: uppercase;
        line-height: 1.15rem;
        margin-top: 0;
        font-size: 0.85rem;
        font-weight: 500;

        &.readed {
          color: lighten(black, 70%);
        }

        @media(max-width: 425px) {
          line-height: 1.5rem;
          margin-top: 1rem;
          font-size: 1.2rem;
        }

      }


      /*&:before {
        content: "";
        position: absolute;
        bottom: 0;
        height: 66px;
        background-image: linear-gradient(to top, #ffffff, rgba(255, 255, 255, 0.4), transparent);
        width: 100%;
        left: 0;
      }*/

      &::-webkit-scrollbar {
        width: 5px;
      }

      //1C4347

      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background: #000000;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #000000;
      }

      li {
        cursor: pointer;
        padding-bottom: 0.7rem;
        padding-top: 0.6rem;
        border-bottom: solid 1px #DFDEEC;

        @at-root body:not(.touch) #{&} {
          &:hover {
            span {
              text-decoration: underline;
            }
          }
        }

        &:last-child {
          border: 0;
        }

      }

      a {
        font-size: 0.81rem;
        font-weight: 400;
        //color: #969696;
        color: rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        &.done {
          color: rgba(0, 0, 0, 0.55);
        }
        &:not(.done){
          color: black;
        }
        i {
          margin-right: 0.5rem;
          font-size: 1rem;
          transform: translate(0px, 2px);

          @media(max-width: 425px) {
            font-size: 1.6rem;
            margin-right: 1.2rem;
          }

          &.ico-klesia-progress {
            color: #4EC575;
          }
          &.ico-klesia-progress-dot {
            //color: lighten(black, 70%);
            color: black;
          }

        }
        span {
          line-height: 1.1rem;
        }
      }

    }

    .articles-test {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 0;
      //overflow-y: scroll;
      //overflow-x: hidden;
      //-webkit-overflow-scrolling: touch;

      &.open {
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.63);
        }
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #257fbc;
      }

      &::-webkit-scrollbar-thumb {
        background: darken( #257fbc, 10% );
      }

      &::-webkit-scrollbar-thumb:hover {
        background: lighten( #257fbc, 80% );
      }

      > ul {
        width: 100%;
        // max-width: 1140px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 5rem;

        @media (max-width: 425px) {
          margin-bottom: 0;
        }

        > li {
          flex: 0 calc(100% / 4);
          // padding: 15px;
          padding: 9px;
          box-sizing: border-box;

          @media (min-width: 1700px) {
            flex: 0 calc(100% / 5);
          }

          div.inner {
            width: 100%;
            position: relative;
            background: white;
            box-shadow: 13px 29px 76px -20px rgba(0,0,0,0.1);
            border-radius: 6px;
            // overflow: hidden;
          }

        }

      }

    }

  }

  .background-menu {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    //background: #4d49fc;
    //background: #2e3dcc;
    background: white;
    opacity: 0;
  }

  .chapitre {
    &.non_lu {
      .chapitre-wallpaper {
        opacity: 0.3;
      }
      .infosCategory {
        color: lighten(#000, 70%) !important;
      }
    }

    &.no-img {
      .chapitre-wallpaper {
        background-size: 50%;
      }
    }
  }

}

@import "./menu-media.scss";
