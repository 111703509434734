@font-face {
  font-family: "icomoon-klesia";
  src: url("icomoon-klesia.eot?47cxjj");
  src: url("icomoon-klesia.eot?47cxjj#iefix") format("embedded-opentype"),
    url("icomoon-klesia.ttf?47cxjj") format("truetype"),
    url("icomoon-klesia.woff?47cxjj") format("woff"),
    url("icomoon-klesia.svg?47cxjj#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ico-klesia-"], [class*=" ico-klesia-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon-klesia' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-klesia-le-rapport-annuel-dactivites:before {
  content: "\e900";
}
.ico-klesia-2019:before {
  content: "\e901";
}
.ico-klesia-arrow-cci:before {
  content: "\e902";
}
.ico-klesia-arrow-left-rounded:before {
  content: "\e903";
}
.ico-klesia-arrow-left:before {
  content: "\e904";
}
.ico-klesia-arrow-right-rounded:before {
  content: "\e905";
}
.ico-klesia-arrow-right:before {
  content: "\e906";
}
.ico-klesia-byridmi .path1:before {
  content: "\e907";
  color: rgb(0, 0, 0);
}
.ico-klesia-byridmi .path2:before {
  content: "\e908";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-klesia-byridmi .path3:before {
  content: "\e909";
  margin-left: -3.529296875em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-klesia-facebook:before {
  content: "\e90a";
}
.ico-klesia-instagram:before {
  content: "\e90b";
}
.ico-klesia-kiosque:before {
  content: "\e90c";
}
.ico-klesia-menu:before {
  content: "\e90d";
}
.ico-klesia-next-article:before {
  content: "\e90e";
}
.ico-klesia-prev-article:before {
  content: "\e90f";
}
.ico-klesia-progress-dot:before {
  content: "\e910";
}
.ico-klesia-progress:before {
  content: "\e911";
}
.ico-klesia-read-circle:before {
  content: "\e912";
}
.ico-klesia-read-public:before {
  content: "\e913";
}
.ico-klesia-read:before {
  content: "\e914";
}
.ico-klesia-ridmi .path1:before {
  content: "\e915";
  color: rgb(0, 0, 0);
}
.ico-klesia-ridmi .path2:before {
  content: "\e916";
  margin-left: -3em;
  color: rgb(0, 0, 0);
  opacity: 0.45;
}
.ico-klesia-search:before {
  content: "\e917";
  color: #fff;
}
.ico-klesia-time:before {
  content: "\e918";
}
.ico-klesia-triangle-intro:before {
  content: "\e919";
}
.ico-klesia-twitter:before {
  content: "\e91a";
}
.ico-klesia-search-ph:before {
  content: "\e91b";
}
.ico-klesia-PH:before {
  content: "\e91c";
  color: #fff;
}
.ico-klesia-article-menu:before {
  content: "\e91d";
  color: #fff;
}
.ico-klesia-print:before {
  content: "\e91e";
  color: #14205d;
}
.ico-klesia-quote1:before {
  content: "\e91f";
}
.ico-klesia-quote2:before {
  content: "\e920";
}
