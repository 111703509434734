//     padding-right: 1rem;

@media (min-height: 900px) and (max-width: 1025px){

  .klesia {
    .main {
      .header {
        padding-right: 1rem;
        width: calc(100vw - 72px);
        left: 72px;

        &.articleView {
          padding-top: calc(0.7rem + 6px);
        }

        &.menuNumeroShow, &.isSearching, &.menuShow, &.isPage {
          padding-top: 0.7rem;
        }

        .scroll-block {
          & > div:first-child {
            flex-basis: 0;
            display: none;
          }
          .scroll-inner {
            flex-basis: calc(100%);
            max-width: 100%;
          }
        }

      }
    }
  }

}

@media (max-width: 425px) {

  .klesia {
    .main {
      .header {
        width: calc(100%);
        height: 60px;
        left: 0;

        &.articleView {
          padding-top: calc(0.7rem + 6px);
        }

        &.menuNumeroShow, &.isSearching, &.menuShow, &.isPage {
          padding-top: 0.7rem;
        }

        &:not(.articleView) {
          .scroll-inner {
            display: none;
          }
        }

        &.menuShow {
          .inner h1 a {
            color: white;
          }
        }

        &.menuShow {
          // background: #2a575d;
          background: white;

          .scroll-block {
            visibility: hidden;
          }

        }

        .scroll-block {
          position: absolute;
          top: 0;
          transform: translateY(-1px);
        }

        .menu-numero {
          display: none;
        }

      }
    }
  }

}
