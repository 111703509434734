@import "../../../mixins.scss";

.klesia {
  .article {

    .articleHeader {
      background: white;
      width: 100%;
      max-width: calc(1025px + 4rem);
      margin: 0 auto;
      padding-top: 2rem;
      padding-left: 2rem;
      padding-right: 2rem;

      @media (max-width: 425px) {
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 0;
      }

      .infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        @media (max-width: 425px) {
          font-size: 1.3rem;
        }

        .infos-inner {
          display: flex;
          flex-direction: row;
          width: 100%;
          justify-content: center;
          overflow: visible;

          @media (max-width: 425px) {
            flex-direction: column;
          }

          .outer-i {
            display: flex;
            flex-direction: row;
            color: black;
            margin-left: 0.8rem;
            align-items: center;
            flex-shrink: 0;

            &.is-only-read {
              display: none;
            }

            .time, .status {
              margin-right: 0.5rem;
            }

            @media (max-width: 425px) {
              margin-left: 0;
              margin-top: 0.9rem;
              display: flex !important;

              &:not(.is-only-read){
                display: none !important;
              }

              .time, .status {
                margin-right: 0.5rem;
              }
            }

            i {
              color: #00d0c5;
              margin-right: 0.3rem;
            }

            span {
              color: #000000;
              font-weight: 500;
              margin-right: 0.5rem;
              white-space: nowrap;
            }

          }

          .category-block {
            display: flex;
            flex-direction: row;
            align-items: center;

            .inner-titles {
              display: flex;
              flex-direction: row;
              max-width: 40vw;
              flex-wrap: nowrap;

              @media (min-width: 1440px) {
                max-width: 30vw;
              }

              @media (max-width: 1024px) {
                max-width: 40vw;
                flex-wrap: nowrap;
              }

              @media (max-width: 425px) {
                max-width: 74vw;
              }

              .subcategory {
                font-weight: 400;
              }

              span {
                font-weight: 600;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;

                @media (max-width: 425px) {
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }

            }

            .number {
              border-radius: 100%;
              width: 1.7rem;
              height: 1.7rem;
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              margin-right: 0.6rem;
              color: white;
              padding-top: 0.06rem;
            }
          }
        }

      }

      h1 {
        font-weight: 400;
        margin-bottom: 2rem;
        text-align: center;
        font-size: 50px;
        color: #000000;
        line-height: 60px;

        @media (max-width: 1024px){
          font-size: 2.5rem;
          line-height: 2.8rem;
          margin-bottom: 1.2rem;
        }

        @media (max-width: 425px) {
          font-size: 2.7rem;
          line-height: 2.9rem;
          margin-bottom: 0.2rem;
          margin-top: 1.5rem;
          text-align: left;
        }
      }

      .imageOuter {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;

        @media (max-width: 1024px) {
          //display: block;
          display: flex;
        }

        @media (max-width: 1024px) {
          margin-top: 1.8rem;
        }

        img {
          //object-fit: cover;
          will-change: opacity;
          opacity: 0;
          max-width: 100%;
          object-fit: cover;
          background: #dedede;

          @media (max-width: 1024px) {
            height: auto !important;
            min-height: 238px;
          }

          &.loaded {
            opacity: 1;
            transition: opacity 0.3s ease 0.200s;
          }

        }
      }

    }
  }
}
